import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import { fromJS } from 'immutable'

import Container from '../../components/Container';
import Box from '../../components/Box';
import Text from '../../components/Text';
import Button from '../../components/Button';

import theme from '../../components/ThemeProvider/theme';
import Form from './Form';
import { selectFirestore } from '../../services/firebase/selectors';

import { ReduxContext } from '../../stores/surveyStore';

class Questions extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.answers) return prevState;
    if (!nextProps.answers) return {};
    const answers = nextProps.answers[nextProps.answerId];
    if (!answers) return {};
    const others = nextProps.questions.reduce((oths, { node: { modules } }, index) => modules.reduce((os, {
      options,
    }, key) => {
      const name = `${index}-${key}`;
      if (!options) return os;
      const othIndex = options.findIndex((opt) => opt.special === 'other');
      if (othIndex === -1) return os;
      os[`${name}[${othIndex}]`] = answers[name][othIndex];
      return os;
    }, oths), {});
    return {
      answers,
      timers: answers.timers,
      others: fromJS(others),
    }
  }

  state = {}

  render() {
    const {
      isMobile,
      questions,
    } = this.props;
    const { answers, others, timers } = this.state;
    let offset = 0;

    if (!answers) return null
    return (
      <ReduxContext.Provider value={{ others, timers }}>
        <Box bg="variations.gray.1">
          <Formik initialValues={answers}>
            {(formikProps) => questions.map(({ node }, index) => {
              if (index > 0) {
                offset += questions[index - 1].node.modules.length
              }
              return (
                <div key={index}>
                  <Container pt={theme.headerHeight} bg="white">
                    <Box
                      borderBottom="3px solid"
                      borderColor="variations.gray.2"
                      py="1em"
                    >
                      <Text.h2
                        color="blue"
                        whiteSpace="pre-wrap"
                        lineHeight="1.75"
                        mb="0.5em"
                      >{node.title}</Text.h2>
                      <Text.h4
                        color="variations.gray.7"
                        whiteSpace="pre-wrap"
                      >{node.subtitle}</Text.h4>
                    </Box>
                  </Container>
                  <Container pb="2rem" bg="white" position="relative" px="0" pt="0.5em">
                    <Form
                      {...formikProps}
                      pageIndex={index}
                      modules={node.modules}
                      gap="4rem"
                      isMobile={isMobile}
                      offset={offset}
                      viewMode
                    />
                  </Container>
                </div>
              )
            })}
          </Formik>
          <Container bg="white">
            <Box py="2em" textAlign="center">
              <Button onClick={() => window.history.back()}>回上頁</Button>
            </Box>
          </Container>
        </Box>
      </ReduxContext.Provider>
    )
  }
}

export default selectFirestore(['answers'])(Questions);
