import React from 'react';

import Container from '../components/Container'
import Text from '../components/Text'
import Box from '../components/Box'
import Link from '../components/Link'

const AdminPage = ({ pages }) => {
  return (
    <Container py="2em">
      <Text.h1>上線中問卷</Text.h1>
      <ul>
        {pages.map(({ slug, title }) => (
          <Box is="li" my="1em" key={slug}>
            <Link to={`/admin/${slug}`}>{title}</Link>
          </Box>
        ))}
      </ul>
    </Container>
  );
};

export default AdminPage;
