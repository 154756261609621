import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby'

import { firebaseConnect } from 'react-redux-firebase';

class LoginPage extends React.PureComponent {
  componentDidMount() {
    const { firebase } = this.props;
    firebase.logout().then(() => navigate('/admin/login'));
  }

  render() {
    return null;
  }
}

LoginPage.propTypes = {
  firebase: PropTypes.shape({
    logout: PropTypes.func,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default firebaseConnect()(LoginPage);
