import React, { createElement, PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import tag from 'clean-tag';
import get from 'lodash/get';
import loSortBy from 'lodash/sortBy';

import {
  width,
  space,
  color,
  themeGet,
  style,
} from 'styled-system';

import theme from '../ThemeProvider/theme';
import Text from '../Text';
import SortableTh from './SortableTh';

import blacklist from '../utils/blacklist';

const StyledTable = styled(tag)`
  ${width}
  ${space}
  ${color}
  thead th {
    border-bottom: 2px solid ${themeGet('colors.variations.gray.6')};
    text-align: left;
  }
  tbody td {
    border-bottom: 1px solid ${themeGet('colors.variations.gray.4')};
  }
  td, th {
    padding: 0.75em;
    ${style({
      prop: 'rowHeight',
      cssProperty: 'height',
      numberToPx: true,
    })}
  }
`;

class Table extends PureComponent {
  state = {
    sortBy: this.props.sortBy,
    desc: true,
  }

  handleSortClick = (key) => () => {
    const { sortBy, desc } = this.state;
    if (key === sortBy) {
      this.setState({ desc: !desc });
    } else {
      this.setState({ sortBy: key, desc: true });
    }
  }

  render() {
    const { columns, data, showIndex, toolbox, ...props } = this.props;
    const { sortBy, desc } = this.state;
    let sortedData = data;
    if (sortBy) {
      sortedData = desc
        ? loSortBy(data, sortBy).slice().reverse()
        : loSortBy(data, sortBy);
    }
    const filteredCols = columns.filter(Boolean).filter(({ key }) => key)
    return (
      <StyledTable
        width={1}
        blacklist={[...blacklist, 'rowHeight', 'sortBy']}
        is="table"
        {...props}
      >
        <thead>
          <tr>
            {showIndex && <th></th>}
            {filteredCols.map(({ key, label }) => (
              <SortableTh
                key={key}
                active={sortBy === key}
                desc={desc}
                onClick={this.handleSortClick(key)}
              >
                {label}
              </SortableTh>
            ))}
            {toolbox && <th />}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => {
            const style = row.error ? { color: theme.colors.red } : {};
            return (
              <tr key={index}>
                {showIndex && <td style={style}>#{index + 1}</td>}
                {filteredCols.map(({ key, renderer }) => (
                  <td key={index + key} style={style}>
                    {createElement(renderer || Text, { data: row }, get(row, key))}
                  </td>
                ))}
                {toolbox && (
                  <td>
                    {createElement(toolbox, { data: row })}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    );
  }
}


Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.node,
    renderer: PropTypes.func,
  })),
  data: PropTypes.arrayOf(PropTypes.object),
  rowHeight: PropTypes.string,
  sortBy: PropTypes.string,
  showIndex: PropTypes.bool,
};

Table.defaultProps = {
  rowHeight: '4em',
};

export default Table;
