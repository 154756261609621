import styled from 'styled-components';
import { themeGet } from 'styled-system';

export default styled.th`
  cursor: pointer;
  ${(props) => props.active && `
    color: ${themeGet('colors.primary')(props)};
    &::after {
      content: '${props.desc ? '▼' : '▲'}';
      vertical-align: text-bottom;
    }
  `}
`;
