import React from 'react';

import Box from './Box';
import Loading from './Loading';

const FullpageLoading = () => (
  <Box
    position="fixed"
    top="0"
    left="0"
    right="0"
    bottom="0"
    bg="rgba(255, 255, 255, 0.8)"
    zOrder={4}
  >
    <Loading position="absolute" left="50%" right="50%" transform="translate(-50%, -50%)" />
  </Box>
);

export default FullpageLoading;
