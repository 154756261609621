import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import subHours from 'date-fns/sub_hours';

import Text from './Text';

const DateString = ({ children, format, tz, ...props }) => (
  <Text {...props}>
    {children ? formatDate(subHours(children, tz), format) : ''}
  </Text>
);

DateString.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  format: PropTypes.string,
  tz: PropTypes.number,
};

DateString.defaultProps = {
  format: 'YYYY-MM-DD HH:mm:ss',
  tz: 0,
};

DateString.dateOnly = (props) => <DateString format="YYYY-MM-DD" {...props} />;

export default DateString;
