import React, { createElement } from 'react';
import map from 'lodash/map'
import get from 'lodash/get'

import Container from '../components/Container';
import Button from '../components/Button';
import Link from '../components/Link';
import Text from '../components/Text';
import Box from '../components/Box';
import Table from '../components/Table';
import DateString from '../components/DateString';

import { selectFirestore } from '../services/firebase/selectors';
// import withResponsive from '../hoc/withResponsive';

const fields = {
  info: {
    name: '4-3',
    email: '4-4',
  },
  '2018-design': {
    name: '4-0',
    email: '4-1',
  },
  '2018-pm': {
    name: '4-0',
    email: '4-1',
  },
  '2018-social': {
    name: '4-0',
    email: '4-1',
  },
  '2019-social': {
    name: '4-0',
    email: '4-1',
  },
  '2019-intern': {
    name: '4-0',
    email: '4-1',
    logic: [
      { key: '2-0', answer: '3' },
      { key: '2-1', answer: '0' },
      { key: '2-2', answer: '3' },
    ]
  },
  '2019-intern-2': {
    name: '4-0',
    email: '4-1',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
    ]
  },
  '2019-first-half': {
    email: '0-0',
  },
  '2019-last-half': {
    email: '0-0',
  },
  '2020-first-half': {
    email: '0-0',
  },
  '2020-last-half': {
    email: '0-0',
  },
  '2021-first-half': {
    email: '0-0',
  },
  '2021-last-half': {
    email: '0-0',
  },
  '2020-recruit': {
    name: '4-1',
    email: '4-2',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
    ],
    position: '4-0',
  },
  '2021-intern': {
    name: '4-1',
    email: '4-2',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
    ],
    position: '4-0',
  },
  '2021-recruit': {
    name: '4-1',
    email: '4-2',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
      { key: '2-6', answer: '2' },
    ],
  },
  '2021-intern-2': {
    name: '4-1',
    email: '4-2',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
    ],
  },
  '2022-recruit': {
    name: '3-0',
    email: '3-1',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
    ],
  },
  '2022-first-half': {
    email: '0-0',
  },
  '2022-last-half': {
    email: '0-0',
  },
  '2023-recruit': {
    name: '4-1',
    email: '4-2',
    logic: [
      { key: '2-0', answer: '1' },
      { key: '2-1', answer: '3' },
      { key: '2-2', answer: '2' },
      { key: '2-3', answer: '10' },
      { key: '2-4', answer: '0' },
      { key: '2-5', answer: '13' },
      { key: '2-6', answer: '2' },
    ],
  },
}

const Answers = ({ slug, questions, ...props }) => {
  let answers = props.answers;
  if (!answers) return null;
  answers = map(answers, (answer, id) => ({ ...answer, doneAt: answer.doneAt.toDate(), id })).filter((answer) => answer.slug === slug);
  const logics = get(fields, [slug, 'logic'])
  const position = get(fields, [slug, 'position'])
  const name = get(fields, [slug, 'name'])
  const email = get(fields, [slug, 'email'])
  return (
    <Container py="2em">
      <Text textAlign="right">共{answers.length}筆資料</Text>
      <Table
        columns={[
          {
            key: name,
            label: '姓名'
          },
          {
            key: email,
            label: 'e-mail'
          },
          logics ? {
            key: 'logic',
            label: '邏輯',
          } : null,
          position ? {
            key: 'position',
            label: '職位',
          } : null,
          {
            key: 'doneAt',
            label: '時間戳記',
            renderer: DateString,
          },
        ]}
        data={answers.map((d) => {
          if (logics) {
            d.logic = `${logics.filter(({ key, answer }) => d[key] == answer).length} / ${logics.length}`;
          }
          if (position) {
            const [p, n] = position.split('-')
            d.position = get(questions, [+p, 'node', 'modules', +n, 'options', +d[position], 'label'])
          }
          return d;
        })}
        toolbox={({ data }) => (<Button is={Link} to={`/admin/${slug}/${data.id}`}>檢視填答</Button>)}
        showIndex
        sortBy="doneAt"
      />
      <Box py="2em">
        <Button is={Link} to="/admin">回列表</Button>
      </Box>
    </Container>
  )
}

export default (props) => createElement(selectFirestore(['answers'])(Answers), props);
