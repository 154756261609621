import React, { createElement } from 'react';
import { graphql } from 'gatsby'
import { Router } from '@reach/router'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import Layout from '../containers/Layout'
import AdminPage from '../containers/AdminPage'
import LoginPage from '../containers/LoginPage'
import LogoutPage from '../containers/LogoutPage'
import adminTemplate from '../templates/admin'
import answerTemplate from '../templates/questions/ViewAll'
import { UserIsAuthenticated, UserIsNotAuthenticated } from '../services/firebase/authHelper'

const routes = [
  {
    path: '/admin',
    component: UserIsAuthenticated(AdminPage),
  },
  {
    path: '/admin/login',
    component: UserIsNotAuthenticated(LoginPage),
  },
  {
    path: '/admin/logout',
    component: UserIsAuthenticated(LogoutPage),
  }
];

export default (props) => {
  const groupBySlug = groupBy(props.data.allSurvey.edges, 'node.slug')
  const welcomePages = map(groupBySlug, (pages) => pages.find(({ node: { type } }) => type === 'welcome').node);
  return (
    <Layout>
      <Router>
        {routes.map(({ path, component }) => createElement(component, { pages: welcomePages, path, key: path }))}
        {reduce(groupBySlug, (all, pages, slug) => {
          const questions = pages.filter(({ node: { type } }) => type === 'questions');
          return all.concat([
            createElement(UserIsAuthenticated(adminTemplate), { key: slug, path: `/admin/${slug}`, slug, questions }),
            createElement(UserIsAuthenticated(answerTemplate), { key: `${slug}answer`, path: `/admin/${slug}/:answerId`, slug, questions }),
          ])
        }, [])}
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query {
    allSurvey {
      edges {
        node {
          slug
          type
          title
          subtitle
          hideButton
          gap
          modules {
            module
            src
            content
            timer
            lock
            multiple
            label
            dimensions
            perRow
            hint
            textarea
            degrees
            leftLabel
            rightLabel
            options {
              label
              src
              special
            }
            decisions {
              src
            }
            schema {
              type
              email
              matchesPreset
              min
              max
              integer
              required
              when {
                name
                is
                then {
                  key
                }
              }
            }
            texts {
              width
              height
              boxes {
                x
                y
                width
                height
                fontSize
                fontWeight
                textAlign
                color
                multiLine
              }
            }
          }
        }
      }
    }
  }
`
